import Vue from 'vue'
import Vuex from 'vuex'
import { ksort } from "@/utils/ksort";
import { askLeCoin } from "@/api/index";
import { loadPageVar } from "@/utils/index";
import { Toast } from "vant";
Vue.use(Vuex)

export const UPDATE_USER = 'UPDATE_USER' // 登录

export default new Vuex.Store({
  state: {
    appid: '74f33c7dbea378b0',
    appkey: '9440b0f4dc9157d8',
    bundle: loadPageVar("bundle") || 'com.gamesbox',
    CYBoxUser: JSON.parse(localStorage.getItem('CYBoxUser')) || null,
    CYBoxLists: JSON.parse(localStorage.getItem('CYBoxLists')) || [],
    CYBoxIDFA: localStorage.getItem('CYBoxIDFA'),
    isLogin: JSON.parse(localStorage.getItem('CYBoxUser')) ? true : false,
    count: null,
    tabbar: true,
    fade: 'fade-out',
    playedList: null,
    userCard: {}
  },
  mutations: {
    incrementIn(state) {
      state.count = 'vux-pop-in'
    },
    incrementOut(state) {
      state.count = 'vux-pop-out'
    },
    tabbarIn(state) {
      state.fade = 'fade-in'
      state.tabbar = true
    },
    tabbarOut(state) {
      state.fade = 'fade-out'
      state.tabbar = false
    },
    loginIn(state) {
      state.isLogin = true
    },
    loginOut(state) {
      localStorage.removeItem('CYBoxUser')
      state.CYBoxUser = null
      state.isLogin = false
    },
    userUpdate(state, info) {
      for (var k in info) {
        state.CYBoxUser[k] = info[k]
      }
      localStorage.setItem('CYBoxUser', JSON.stringify(state.CYBoxUser))
    },
    listUpdate(state, info) {
  
      state.CYBoxLists.map((e, i) => {
        if(e) {
          if(e.userID == info.userID) {
            for(var k in info) {
              state.CYBoxLists[i][k] = info[k]
            }
          }
        }
      })
      localStorage.setItem('CYBoxLists', JSON.stringify(state.CYBoxLists))
    },
    listDelete(state, info) {
      let arr = []
      info.map(k => {
        if(k.model) {
          state.CYBoxLists.map((e, i) => {
            if(e.loginName == k.loginName) {
              delete state.CYBoxLists[i]
            }
          })
        }
      })
      for(let i = 0; i < state.CYBoxLists.length; i++) {
        if(state.CYBoxLists[i]) {
          arr.push(state.CYBoxLists[i])
        }
      }
      localStorage.setItem('CYBoxLists', JSON.stringify(arr))
    },
    IDFAUpdate(state, info) {
      state.CYBoxIDFA = info
    },
    addHistory(state, arr) {
      state.playedList = arr
    },
    CYBoxLists(state, info) {
      var save = true
      state.CYBoxLists.map(e => {
        if(e.userID == info.userID) {
          save = false
        }
      })
      if(save) {
        state.CYBoxLists.push(info)
        localStorage.setItem('CYBoxLists', JSON.stringify(state.CYBoxLists))
      }
    },
    cardUpdate(state, info) {
      state.userCard = info
    },
    [UPDATE_USER](state, userinfo) {
      if (userinfo) {
        localStorage.setItem('CYBoxUser', JSON.stringify(userinfo))
        state.CYBoxUser = userinfo
      }
    }
  },
  getters: {
    count: state => state.count,
    tabbar: state => state.tabbar,
    fade: state => state.fade,
    CYBoxUser: state => state.CYBoxUser,
    CYBoxLists: state => state.CYBoxLists,
    CYBoxIDFA: state => state.CYBoxIDFA,
    isLogin: state => state.isLogin,
    appid: state => state.appid,
    appkey: state => state.appkey,
    time: state => Math.floor(Date.now() / 1000),
    bundle: state => state.bundle,
    playedList: state => state.playedList,
    userCard: state => state.userCard
  },
  actions: {
    [UPDATE_USER]({
      commit
    }, user) {
      commit(UPDATE_USER, user)
    }
  }
})