import qs from 'qs'
import axios from 'axios'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

const uri = "https://lt.76ab.com/Home"
const uriJson = "https://h5sdk.76ab.com/BoxH5/json"
const uriCont = "https://aa.76ab.com/api/"
// const uriCont = "http://gb.localhost.com/api/"

const Http = (params, data) => {
  let option = {
    method: params.method || 'get',
    url: params.url,
    baseURL: uri,
    params: data,
    data: qs.stringify(data),
  }
  if (params.method === 'post') {
    delete option.params
  } else {
    delete option.data
  }
  return new Promise((resolve, reject) => {
    axios(option)
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject(err)
    })
  })
}
const HttpJson = (params, data) => {
  let option = {
    method: params.method || 'get',
    url: params.url,
    baseURL: uriJson,
    params: data,
    data: qs.stringify(data),
  }
  if (params.method === 'post') {
    delete option.params
  } else {
    delete option.data
  }
  return new Promise((resolve, reject) => {
    axios(option)
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject(err)
    })
  })
}
const HttpCont = (params, data) => {
  let option = {
    method: params.method || 'get',
    url: params.url,
    baseURL: uriCont,
    params: data,
    data: qs.stringify(data),
  }
  if (params.method === 'post') {
    delete option.params
  } else {
    delete option.data
  }
  return new Promise((resolve, reject) => {
    axios(option)
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject(err)
    })
  })
}

// 登录
export const askLogin = params => {
  return Http({
    method: 'post',
    url: '/Login/login'
  }, params)
}
// 实名
export const askRealName = params => {
  return Http({
    method: 'post',
    url: '/User/getDetailed'
  }, params)
}
// 修改用户名
export const askSaveName = params => {
  return Http({
    method: 'post',
    url: '/User/saveName'
  }, params)
}
// 修改密码
export const askSavePass = params => {
  return Http({
    method: 'post',
    url: '/User/savePassword'
  }, params)
}
// 绑定手机
export const askSavePhone = params => {
  return Http({
    method: 'post',
    url: '/User/savePhoneNum'
  }, params)
}
// 验证原手机
export const askconfirmPhone = params => {
  return Http({
    method: 'post',
    url: '/User/confirmPhoneCode'
  }, params)
}
// 获取验证码
export const askGetCode = params => {
  return Http({
    method: 'post',
    url: '/Login/getCode'
  }, params)
}
// 解绑手机
export const askunBindPhone = params => {
  return Http({
    method: 'post',
    url: '/User/untyingPhone'
  }, params)
}
// 实名认证
export const askBindCard = params => {
  return Http({
    method: 'post',
    url: '/User/postCard'
  }, params)
}
// 找回密码
export const askFindPass = params => {
  return Http({
    method: 'post',
    url: '/User/codeSavePhone'
  }, params)
}
// 手机号注册
export const askPhoneRegister = params => {
  return Http({
    method: 'post',
    url: '/Login/postPhone'
  }, params)
}
// 注册账号
export const askUserNameRegister = params => {
  return Http({
    method: 'post',
    url: '/Login/postRegister'
  }, params)
}
// 首页
export const askIndex = params => {
  return HttpCont({
    method: 'get',
    url: '/game/index'
  }, params)
}
// 最新
export const askNewlist = params => {
  return HttpCont({
    method: 'get',
    url: '/game/get_new_list'
  }, params)
}
// 最热
export const askHotlist = params => {
  return HttpCont({
    method: 'get',
    url: '/game/get_hot_list'
  }, params)
}
// 详情
export const askDetailed = params => {
  return HttpCont({
    method: 'post',
    url: '/game/detailed'
  }, params)
}
// 礼包
export const askGiftlist = params => {
  return HttpCont({
    method: 'post',
    url: '/game/gift_list'
  }, params)
}
// 个人
export const askUserinfo = params => {
  return HttpCont({
    method: 'post',
    url: '/user/info'
  }, params)
}
// 游戏上报
export const askAddhistory = params => {
  return HttpCont({
    method: 'post',
    url: 'user/add_history'
  }, params)
}
// 历史记录
export const askHistory = params => {
  return HttpCont({
    method: 'post',
    url: 'user/history'
  }, params)
}
export const askUserHistory = params => {
  return HttpCont({
    method: 'post',
    url: 'user/historyList'
  }, params)
}
// 分类
export const askTypelist = params => {
  return HttpCont({
    url: 'game/type_list'
  }, params)
}
// 优惠券列表
export const askCoupon = params => {
  return HttpCont({
    method: 'post',
    url: 'coupon/index'
  }, params)
}
// 领取优惠券
export const askGetCoupon = params => {
  return HttpCont({
    method: 'post',
    url: 'coupon/user_get'
  }, params)
}
// 优惠券列表
export const askListCoupon = params => {
  return HttpCont({
    method: 'post',
    url: 'coupon/user_list'
  }, params)
}
// 实名列表
export const askIncard = params => {
  return HttpCont({
    method: 'post',
    url: 'user/account_in_card'
  }, params)
}
// 实名解除
export const askUncard = params => {
  return HttpCont({
    method: 'post',
    url: 'user/unbinding_card'
  }, params)
}
export const askCandyPay = params => {
  return Http({
    method: 'post',
    url: '/Belief/addWithLeCoin'
  }, params)
}
// 领取礼包
export const askGiftCode = params => {
  return Http({
    method: 'post',
    url: '/User/getGiftCode'
  }, params)
}

export const askCouponIndex = params => {
  return Http({
    method: 'post',
    url: '/CouponPay/index'
  }, params)
}
export const askCouponPay = params => {
  return Http({
    method: 'post',
    url: '/CouponPay/add'
  }, params)
}
// app加载模式初始化
export const askAppInit = () => {
  return HttpJson({
    url: '/app.json?t=' + Date.now()
  })
}
// Agreement
export const askAgreement = () => {
  return HttpJson({
    url: '/agreement.json'
  })
}
// Permit
export const askPermit = () => {
  return HttpJson({
    url: '/permit.json'
  })
}
// Privacy
export const askPrivacy = () => {
  return HttpJson({
    url: '/privacy.json'
  })
}
// Protect
export const askProtect = () => {
  return HttpJson({
    url: '/protect.json'
  })
}
// Statement
export const askStatement = () => {
  return HttpJson({
    url: '/statement.json'
  })
}
// 数据上报
export const askReport = params => {
  return Http({
    method: "post",
    url: '/Index/index'
  }, params)
}
// 卡片详情接口
export const askLeCoin = params => {
  return Http({
    method: "post",
    url: "/LeCoin/index"
  }, params)
}

// 购买会员卡或者折扣卡
export const askBuyLeCoin = params => {
  return Http({
    method: "post",
    url: '/LeCoin/buyingLeCoin'
  }, params)
}

// 每日福利领取
export const askRecived = params => {
  return Http({
    method: 'post',
    url: '/LeCoin/recivedLecoin'
  }, params)
}

// 购买乐币
export const askPayWith = params => {
  return Http({
    method: 'post',
    url: '/LeCoin/payWithLecoin'
  }, params)
}

// 验证订单
export const askVerOrder = params => {
  return Http({
    method: 'post',
    url: '/LeCoin/verOrderWithLecoin'
  }, params)
}

// 数据统计
export const askAnaly = params => {
  return HttpCont({
    url: '/analysis/index/'
  }, params)
}
// 签到详情
export const askSignIndex = params => {
  return HttpCont({
    method: 'post',
    url: '/activity/signIndex'
  }, params)
}
// 执行签到
export const askDoSign = params => {
  return HttpCont({
    method: 'post',
    url: '/activity/doSign'
  }, params)
}
// 我的邀请码
export const askInvitationCode = params => {
  return HttpCont({
    method: 'post',
    url: '/activity/getInvitationCode'
  }, params)
}
// 新用户输入邀请码
export const askReceiveInvitation = params => {
  return HttpCont({
    method: 'post',
    url: '/activity/receiveInvitation'
  }, params)
}
// 显示邀请页详情
export const askGetList = params => {
  return HttpCont({
    method: 'post',
    url: '/activity/getInvitationtList'
  }, params)
}
// 领取活动卡
export const askUseCard = params => {
  return HttpCont({
    method: 'post',
    url: '/activity/useInvitationCard'
  }, params)
}
// 
export const askUploadInvitationView = params => {
  return HttpCont({
    method: 'post',
    url: '/activity/uploadInvitationView'
  }, params)
}
// 充值消费记录
export const askLecoinDetail = params => {
  return HttpCont({
    method: 'post',
    url: '/activity/lecoinDetail'
  }, params)
}

// 微信小程序
export const askGetImg = params => {
  return Http({
    method: 'post',
    url: '/WechatSmall/getImg'
  }, params)
}

// 领取邀请码奖励
export const askDoGetInvitaionCoin = params => {
  return HttpCont({
    method: 'post',
    url: '/activity/doGetInvitaionCoin'
  }, params)
}

// 额外API
const uriFish = "https://api.knowfish.cn"
const HttpFish = (params, data) => {
  let option = {
    method: params.method || 'get',
    url: params.url,
    baseURL: uriFish,
    params: data,
    data: qs.stringify(data),
  }
  if (params.method === 'post') {
    delete option.params
  } else {
    delete option.data
  }
  return new Promise((resolve, reject) => {
    axios(option)
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject(err)
    })
  })
}
// 知鱼初始化
export const askFishInit = params => {
  return HttpFish({
    method: 'post',
    url: '/Api/Common/SdkInit'
  }, params)
}
// 知鱼修改密码
export const askFishPwd = params => {
  return HttpFish({
    method: 'post',
    url: '/Api/Member/ModifyPwd'
  }, params)
} 
//