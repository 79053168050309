import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/customer',
    name: 'Customer',
    component: () => import('../views/Customer.vue'),
  },
  {
    path: '/find',
    name: 'Find',
    component: () => import('../views/Find.vue'),
  },
  {
    path: '/my',
    name: 'My',
    component: () => import('../views/My.vue'),
  },
  {
    path: '/page',
    name: 'Page',
    component: () => import('../views/Page.vue'),
  },
  {
    path: '/pageios',
    name: 'Pageios',
    component: () => import('../views/PageIOS.vue'),
  },
  {
    path: '/card',
    name: 'Card',
    component: () => import('../views/Card.vue')
  },
  {
    path: '/played',
    name: 'Played',
    component: () => import('../views/Played.vue')
  },
  {
    path: '/invitation',
    name: 'Invitation',
    component: () => import('../views/Invitation.vue')
  },
  {
    path: '/vip',
    name: 'Vip',
    component: () => import('../views/Vip.vue')
  },
  {
    path: '/reward',
    name: 'Reward',
    component: () => import('../views/Reward.vue')
  },
  {
    path: '/lecoin',
    name: 'Lecoin',
    component: () => import('../views/Lecoin.vue')
  }
]

const router = new VueRouter({
  // mode: 'mode',
  base: process.env.BASE_URL,
  routes
})
/* 解决 vue-router 跳转报错 */ 
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

export default router
