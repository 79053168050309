import CryptoJS from "crypto-js";

const key = 'kwosuejdlaowidmb'
const iv = '8294678928407162'

function loadPageVar(name) {
  return decodeURI(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURI(name).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}
// 加密
function Encrypt (text) {
  return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
}
// 解密
function Decrypt (text) {
  let decrypted = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return decrypted.toString(CryptoJS.enc.Utf8)
}

function ajax(params, notJson) {
  let xmlhttp = window.XMLHttpRequest ?
    new XMLHttpRequest() :
    new ActiveXObject("Microsoft.XMLHTTP")
  let method = params.method || 'GET'
  let data = params.data || {}
  let url = 'https://aa.76ab.com/api' + params.url
  let str = ''
  if (params.data) {
    for (let attr in data) {
      str += attr + '=' + data[attr] + '&'
    }
    str = str.slice(0, -1)
  }
  xmlhttp.open(method, url, true)
  xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
  xmlhttp.send(str)
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState === 4) {
      if (xmlhttp.status === 200) {
        if(notJson) {
          params.success && params.success(xmlhttp.responseText)
        } else {
          params.success && params.success(JSON.parse(xmlhttp.responseText))
        }
      } else {
        params.fail && params.fail()
      }
    }
  }
}

function ajax2(params, notJson) {
  let xmlhttp = window.XMLHttpRequest ?
    new XMLHttpRequest() :
    new ActiveXObject("Microsoft.XMLHTTP")
  let method = params.method || 'GET'
  let data = params.data || {}
  let url = 'https://lt.76ab.com/Home' + params.url
  let str = ''
  if (params.data) {
    for (let attr in data) {
      str += attr + '=' + data[attr] + '&'
    }
    str = str.slice(0, -1)
  }
  xmlhttp.open(method, url, true)
  xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
  xmlhttp.send(str)
  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState === 4) {
      if (xmlhttp.status === 200) {
        if(notJson) {
          params.success && params.success(xmlhttp.responseText)
        } else {
          params.success && params.success(JSON.parse(xmlhttp.responseText))
        }
      } else {
        params.fail && params.fail()
      }
    }
  }
}

const timeApi = timer => {
  const stampStr = timer.toString()
  let time = new Date(stampStr.length === 13 ? timer : timer * 1000),
    year = time.getFullYear(),
   month = time.getMonth() + 1,
   day = time.getDate()

  month = month < 10 ? "0" + month : month
  day = day < 10 ? "0" + day : day

  return year + "." + month + "." + day 
}

const timeApi2 = timer => {
  const stampStr = timer.toString()
  let time = new Date(stampStr.length === 13 ? timer : timer * 1000),
    year = time.getFullYear(),
   month = time.getMonth() + 1,
   day = time.getDate(),
   hours = time.getHours(),
   minutes = time.getMinutes(),
   seconds = time.getSeconds()

  month = month < 10 ? "0" + month : month
  day = day < 10 ? "0" + day : day
  hours = hours < 10 ? "0" + hours : hours
  minutes = minutes < 10 ? "0" + minutes : minutes
  seconds = seconds < 10 ? "0" + seconds : seconds

  return hours + ":" + minutes + ":" + seconds
}

const timeApi3 = timer => {
  const stampStr = timer.toString()
  let time = new Date(stampStr.length === 13 ? timer : timer * 1000),
    year = time.getFullYear(),
   month = time.getMonth() + 1,
   day = time.getDate(),
   hours = time.getHours(),
   minutes = time.getMinutes(),
   seconds = time.getSeconds()

  month = month < 10 ? "0" + month : month
  day = day < 10 ? "0" + day : day
  hours = hours < 10 ? "0" + hours : hours
  minutes = minutes < 10 ? "0" + minutes : minutes
  seconds = seconds < 10 ? "0" + seconds : seconds

  return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
}

var u = navigator.userAgent;
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

export {
  loadPageVar,
  Encrypt,
  Decrypt,
  ajax,
  ajax2,
  timeApi,
  timeApi2,
  timeApi3,
  isAndroid,
  isiOS
}