<template>
  <div :class=" item.id ? 'strip' : 'strip x_default'">
    <div class="strip_item" @click="onclick(item)">
      <div class="strip_view">
        <div class="strip_pos">
          <p class="strip_iconview">
            <img :src="item.icon" @error="imgError" alt="" class="strip_icon" />
          </p>
          <div>
            <div class="strip_top">
              <span class="strip_name">{{ item.name }}</span>
              <span v-if="item.discount" class="strip_discount">{{item.discount}}</span>
            </div>
            <div class="strip_line">
              <span class="strip_type">{{ item.type ? item.type.join(" ") : null }}</span>
              <span class="strip_tip" :style="'color:'+ item.color + ';border: 1px solid' + item.color ">{{ item.tip }}</span>
            </div>
            <span class="strip_des">{{ item.des }}</span>
          </div>
        </div>
        <span class="strip_start" v-if="isHome">开始</span>
      </div>
      <img :src="item.image + '?t=' + Date.now()" alt="" class="strip_banner" v-if="index == 0" />
    </div>
  </div>
</template>
<script>
import defaultImage from "@/assets/1.jpg";
import { isiOS } from "@/utils/index";
export default {
  props: ["item", 'route', "index", "isHome", "routeios"],
  data() {
    return {};
  },
  methods: {
    onclick(item) {
      if(item.id != null) {
        store.commit('incrementIn')
        // store.commit('tabbarOut')
        if(isiOS) {
          this.$router.push(this.routeios + '?id=' + item.id)
        } else {
          this.$router.push(this.route + '?id=' + item.id)
        }
      }
    },
    imgError() {
      let img = event.srcElement;
      img.src = defaultImage;
      img.onerror = null; //防止闪图
    },
  }
}
</script>
<style lang="stylus" scoped>

@import './common.styl';

.strip_item {
  display: flex;
  flex-direction column
  padding-left: 10px;
  margin: 18px 0;
  justify-content: space-between;
}

.strip_view {
  display: flex;
  flex-direction row
  align-items: center;
  justify-content: space-between;
}

.strip_iconview {
  height: 68px;
  width: 68px;
  border-radius: 15px;
  margin-right: 10px;
}

.strip_icon {
  height: 100%;
  width: 100%;
  border-radius: 15px;
}

.strip_pos {
  display: flex;
  align-items: center;
}
.strip_top {
  display flex
  align-items center
}
.strip_name {
  font-size: 18px;
  font-weight: 600;
  margin-right 4px
  max-width 38vw
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.strip_discount {
  color #ffffff
  background linear-gradient(90deg, $themeMax 30%, $themeMin 100%)
  height 16px
  line-height 16px
  font-size 10px
  border-radius 2px
  padding 0 3px
  min-width 32px
}

.strip_line {
  display flex
  align-items center
  margin 2px 0 0
}

.strip_type {
  color: #959595;
  font-size: 12px;
  margin-right: 4px;
  font-weight 600
}

.strip_tip {
  color: #f21919;
  font-size: 12px;
  border: 1px solid #f21919;
  border-radius: 4px;
  display flex
  justify-content center
  align-items center
  height 16px
  padding 0 4px
}

.strip_des {
  font-size: 12px;
  color: #959595;
  width 50vw
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display inline-block;
  margin-top 2px
}

.strip_start {
  height: 24px;
  line-height: 24px;
  width: 54px;
  min-width: 54px;
  text-align: center;
  color: #fff;
  border-radius: 25px;
  background: linear-gradient(90deg, #ff5659 30%, #ff0103 100%);
  font-size: 16px;
  margin-right: 14px;
}
.strip_banner
  width 98%
  margin-top 8px
  border-radius 5px
</style>
