<template>
  <div class="container" @touchstart="touchStart" @touchend="touchEnd">
    <div class="home_bg" ref="homeBg">
      <div class="home_l">
        <span class="home_tj">推荐</span>
        <i class="home_tj_line"></i>
      </div>
      <!-- <div class="home_xiaonian">
        小年卡充值加送10%
      </div> -->
      <div class="home_r">
        <!-- <img src="../assets/cu.png" class="home_cu" alt="" @click="openKefu"> -->
        <img src="../assets/gamebtn.png" class="home_gamebtn" v-if="isLogin" alt="" @click="openPlayed">
      </div>
    </div>
    <Swipe :lists="bannerList" route="/page" routeios="/pageios"></Swipe>
    <Notice :message="message"></Notice>
    <Hot :lists="hotList" route="/page" routeios="/pageios"></Hot>
    <Plate
      title="人气热门"
      :lists="plateListHot"
      route="/page"
      routeios="/pageios"
      more="/more"
      type="hot"
    ></Plate>
    <Plate
      title="新游上榜"
      :lists="plateListNew"
      route="/page"
      routeios="/pageios"
      more="/more"
      type="new"
    ></Plate>
    <div
      :style="'margin-bottom: calc(150px + constant(safe-area-inset-bottom));margin-bottom: calc(150px + env(safe-area-inset-bottom));'"
      class="home_bottom"
    >
      - - - 我可是有底线的 - - -
    </div>
    <transition :name="fade">
      <Played
        :lists="playedList"
        v-if="playedList"
        v-show="playShow"
        route="/page"
        routeios="/pageios"
      ></Played>
    </transition>
   
    <Signin v-if="isSignin" :havesign="isHaveSign" v-on:clickclose="isSignin = false" v-on:clickevent="goQian" :duration="duration"></Signin>

    <van-action-sheet
      v-model="accountShow"
      :actions="actions"
      cancel-text="取消"
      description="实名账号信息超过3个,请点击解除其他实名的账号"
      @select="onSelect"
    />
  </div>
</template>

<script>
import Swipe from "@/components/Swipe.vue";
import Notice from "@/components/Notice.vue";
import Hot from "@/components/Hot.vue";
import Plate from "@/components/Plate.vue";
import Played from "@/components/Played.vue";
import Signin from '@/components/Signin.vue'

import { mapGetters } from "vuex";
import { ksort } from "@/utils/ksort";
import { Form, Toast, Dialog } from "vant";

import md5 from "blueimp-md5";
import { ajax } from "@/utils/index";
import { askSignIndex, askDoSign, askIncard, askUncard } from "@/api";

export default {
  name: "Home",
  components: {
    Swipe,
    Notice,
    Hot,
    Plate,
    Played,
    Signin
  },
  data() {
    return {
      actions: [],
      accountShow: false,
      setUserList: false,
      playShow: true,
      t1: 0,
      t2: 0,
      startScroll: 0,
      availHeight: window.screen.availHeight,
      message: [
        { id: null, text: "占位占位占位占位占位占位占位占位占位" },
        { id: null, text: "占位占位占位占位占位占位占位占位占位" },
        { id: null, text: "占位占位占位占位占位占位占位占位占位" },
      ],
      // playedList: null,
      bannerList: [
        {
          id: null,
          image: null,
        },
        {
          id: null,
          image: null,
        },
        {
          id: null,
          image: null,
        },
        {
          id: null,
          image: null,
        },
      ],
      hotList: [
        {
          id: null,
          icon: null,
          name: "占占占占",
          tip: "占占占占",
        },
        {
          id: null,
          icon: null,
          name: "占占占占",
          tip: "占占占占",
        },
        {
          id: null,
          icon: null,
          name: "占占占占",
          tip: "占占占占",
        },
        {
          id: null,
          icon: null,
          name: "占占占占",
          tip: "占占占占",
        },
      ],
      plateListHot: [
        {
          id: null,
          icon: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          tip: "占位",
          des: "占位占位占位占位占位占位",
          image: null,
        },
        {
          id: null,
          icon: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          tip: "占位",
          des: "占位占位占位占位占位占位",
          image: null,
          discount: null,
        },
        {
          id: null,
          icon: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          tip: "占位",
          des: "占位占位占位占位占位占位",
          image: null,
          discount: null,
        },
        {
          id: null,
          icon: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          tip: "占位",
          des: "占位占位占位占位占位占位",
          image: null,
          discount: null,
        },
        {
          id: null,
          icon: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          tip: "占位",
          des: "占位占位占位占位占位占位",
          image: null,
          discount: null,
        },
      ],
      plateListNew: [
        {
          id: null,
          icon: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          tip: "占位",
          des: "占位占位占位占位占位占位",
          image: null,
        },
        {
          id: null,
          icon: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          tip: "占位",
          des: "占位占位占位占位占位占位",
          image: null,
          discount: null,
        },
        {
          id: null,
          icon: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          tip: "占位",
          des: "占位占位占位占位占位占位",
          image: null,
          discount: null,
        },
        {
          id: null,
          icon: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          tip: "占位",
          des: "占位占位占位占位占位占位",
          image: null,
          discount: null,
        },
        {
          id: null,
          icon: null,
          name: "占位占位占位",
          type: ["占位", "占位"],
          tip: "占位",
          des: "占位占位占位占位占位占位",
          image: null,
          discount: null,
        },
      ],
      isSignin: false,
      isHaveSign: "",
      duration: 0,
    };
  },
  computed: {
    ...mapGetters(["fade", "CYBoxUser", "playedList", "isLogin"]),
  },
  mounted() {
    // 初始化

    this.getSign()

    if (judgeBigScreen()) {
      this.$refs.homeBg.className = "home_bg homeBgAndroid";
    }
    /**
     * IndexData 轮播图数据  IndexMd5
     * RecData 火爆推荐数据  RecMd5
     * NewData 新游上榜数据 NewMd5
     * HotData 人气热门数据 HotMd5
     * MessageData 公告 MessageMd5
     * */
    if (localStorage.getItem("IndexData"))
      this.bannerList = JSON.parse(localStorage.getItem("IndexData"));
    if (localStorage.getItem("RecData"))
      this.hotList = JSON.parse(localStorage.getItem("RecData"));
    if (localStorage.getItem("NewData"))
      this.plateListNew = JSON.parse(localStorage.getItem("NewData"));
    if (localStorage.getItem("HotData"))
      this.plateListHot = JSON.parse(localStorage.getItem("HotData"));
    if (localStorage.getItem("MessageData"))
      this.message = JSON.parse(localStorage.getItem("MessageData"));

    ajax({
      url: "/game/home",
      success: res => {
        if (res.code === 1) {
          let rotation = [],
            data = [];
          res.data.data.map((e) => {
            data.push({
              id: e.id,
              icon: e.icon,
              name: e.name,
              tip: e.type_name + " · " + e.play_name[0],
            });
          });
          res.data.rotation.map((e) => {
            rotation.push({
              id: e.game_id,
              image: e.image_url,
              gb_game_list: e.gb_game_list,
              url: e.url,
            });
          });
          var rotStr = JSON.stringify(rotation);
          var daStr = JSON.stringify(data);
          var msgStr = JSON.stringify(res.data.notify);
          if (localStorage.getItem("IndexMd5") !== md5(rotStr)) {
            // 更新本地数据
            this.bannerList = rotation;
            localStorage.setItem("IndexData", rotStr);
            localStorage.setItem("IndexMd5", md5(rotStr));
          }
          if (localStorage.getItem("RecMd5") !== md5(daStr)) {
            // 更新本地数据
            this.hotList = data;
            localStorage.setItem("RecData", daStr);
            localStorage.setItem("RecMd5", md5(daStr));
          }
          if (localStorage.getItem("MessageMd5") !== md5(msgStr)) {
            this.message = res.data.notify;
            localStorage.setItem("MessageData", msgStr);
            localStorage.setItem("MessageMd5", md5(msgStr));
          }
        }
      },
    });
    ajax({
      url: "/game/get_new_list",
      success: res => {
        if (res.code === 1) {
          let data = [];
          res.data.map((e) => {
            switch (e.type_name) {
              case "传奇":
                e.color = "#f21919";
                break;
              case "仙侠":
                e.color = "#64c60d";
                break;
              case "魔幻":
                e.color = "#8138c0";
                break;
              case "三国":
                e.color = "#f29d23";
                break;
              default:
                break;
            }
            data.push({
              id: e.id,
              icon: e.icon,
              name: e.name,
              type: e.play_name,
              tip: e.type_name,
              image: e.image,
              des: e.introduction,
              color: e.color,
            });
          });
          var newStr = JSON.stringify(data);
          if (localStorage.getItem("NewMd5") !== md5(newStr)) {
            // 更新本地数据
            this.plateListNew = data;
            localStorage.setItem("NewData", newStr);
            localStorage.setItem("NewMd5", md5(newStr));
          }
        }
      },
    });
    ajax({
      url: "/game/get_hot_list",
      success: res => {
        if (res.code === 1) {
          let data = [];
          res.data.map((e) => {
            switch (e.type_name) {
              case "传奇":
                e.color = "#f21919";
                break;
              case "仙侠":
                e.color = "#64c60d";
                break;
              case "魔幻":
                e.color = "#8138c0";
                break;
              case "三国":
                e.color = "#f29d23";
                break;
              default:
                break;
            }
            data.push({
              id: e.id,
              icon: e.icon,
              name: e.name,
              type: e.play_name,
              tip: e.type_name,
              image: e.image,
              des: e.introduction,
              color: e.color,
            });
          });
          var hotStr = JSON.stringify(data);
          if (localStorage.getItem("HotMd5") !== md5(hotStr)) {
            // 更新本地数据
            this.plateListHot = data;
            localStorage.setItem("HotData", hotStr);
            localStorage.setItem("HotMd5", md5(hotStr));
          }
        }
      }
    })

  },
  created() {
    window.addEventListener("scroll", this.handleScroll, false);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, false);
  },
  beforeRouteEnter(to, from, next) {
    var timer = setTimeout(() => {
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
      clearTimeout(timer);
    }, 1);
    next((vm) => {
      vm.getHistory();
      window.addEventListener("scroll", vm.handleScroll, false);
    });
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll, false);
    next();
  },
  methods: {
    onSelect(item) {
      Dialog.confirm({
        title: '提示',
        message: '确认解除账号实名?',
      }).then(() => {
        var params = {
          token: this.CYBoxUser.loginToken,
          time: Math.floor(Date.now() / 1000),
          unbindingCardId: item.id
        }
        params.sign = ksort(params, 'qsdhajasfas123sq')
        askUncard(params).then(res => {
          if(res.code == 1) {
            var actions = this.actions
            var num = 0
            for(let i = 0; i < actions.length; i++) {
              if(actions[i].id == item.id) {
                num = i
              }
            }
            actions.splice(num,1)
            this.actions = actions
          }
        })
      }).catch(() => {
        
      });
    },
    handleScroll() {
      this.t2 = window.scrollY;
      var timer = setInterval(() => {
        this.t1 = window.scrollY;
        if (this.t2 == this.t1) {

          clearInterval(timer);
        }
      }, 1000);
    },
    touchStart() {
      this.startScroll = window.scrollY;
    },
    touchEnd() {},
    getHistory() {
      if (this.CYBoxUser) {
        var param = {
          token: this.CYBoxUser.loginToken,
          time: Math.floor(Date.now() / 1000),
        };
        param.sign = ksort(param, "qsdhajasfas123sq");
        ajax({
          method: "POST",
          url: "/user/history",
          data: param,
          success: res => {
            if (res.code === 1) {
              var data = [];
              res.data.map((e) => {
                data.push({
                  id: e.gb_game_list.id,
                  icon: e.gb_game_list.icon,
                  name: e.gb_game_list.name,
                });
              });
              store.commit("addHistory", data[0] ? data : null);
            }
          }
        })
      }
    },
    openKefu() {
      try {
        window.webkit.messageHandlers.CYPay.postMessage("https://h5sdk.76ab.com/miniH5/kefu.html");
      } catch (error) {}
    },
    openPlayed() {
      store.commit('incrementIn')
      // store.commit("tabbarOut");
      this.$router.push('/played')
    },
    getSign() {
      if(this.CYBoxUser) {
        var params = {
          token: this.CYBoxUser.loginToken,
          time: Math.floor(Date.now() / 1000)
        }
        params.sign = ksort(params, 'qsdhajasfas123sq')
        askSignIndex(params).then(res => {
          if(res.code == 1) {
            var dataRes = res.data.data
            if(dataRes.dayIsSign == 0) {
              this.duration = dataRes.Duration
              this.isSignin = true
              this.isHaveSign = "立即签到"
            }
          }
        })
      }
    },
    goQian() {
      if(this.CYBoxUser) {
        var params = {
          token: this.CYBoxUser.loginToken,
          time: Math.floor(Date.now() / 1000)
        }
        params.sign = ksort(params, 'qsdhajasfas123sq')
        askDoSign(params).then(res => {
          if(res.code == 1) {
            this.isHaveSign = "已签到"
            if(this.duration == 10) {
              this.duration = 1
            } else {
              this.duration++
            }
          } else {
            if(res.message == "实名绑定信息超过上限，账号异常") {
              askIncard(params).then(res => {
                if(res.code == 1) {
                  let ids = [], datas = []
                  for(let i = 0; i < res.data.length; i++) {
                    var id = res.data[i].id
                    if(ids.indexOf(id) == -1) {
                      ids.push(id)
                      datas.push({
                        id: res.data[i].id,
                        name: res.data[i].username
                      })
                    }
                  }
                  this.actions = datas
                  this.accountShow = true
                }
              })
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          }
        })
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.home_bg {
  background: url('../assets/homebg.png') no-repeat;
  background-size: 100%;
  height: calc(210px + constant(safe-area-inset-bottom));
  height: calc(210px + env(safe-area-inset-bottom));
  width 100%
  position: absolute;
}
.home_l
  display flex
  flex-direction: column;
  width 46px
  text-align center
  align-items: center;
  margin-top: calc(10px + constant(safe-area-inset-bottom));
  margin-top: calc(10px + env(safe-area-inset-bottom));
  margin-left: calc(2% + 12px)
.home_xiaonian
  margin-top: calc(10px + constant(safe-area-inset-bottom));
  margin-top: calc(10px + env(safe-area-inset-bottom));
  position absolute
  top calc(10px + constant(safe-area-inset-bottom));
  top calc(10px + env(safe-area-inset-bottom));
  margin-left: calc(2% + 18px + 46px)
  font-size 12px
.home_r
  position absolute
  right 8px
  top calc(8px + constant(safe-area-inset-bottom));
  top calc(8px + env(safe-area-inset-bottom));
.home_cu
  height 32px
  width 32px
.home_gamebtn
  height 32px
  width 32px
  margin-left: 2px;
.home_tj
  color #fff
  font-size 18px
.home_tj_line
  width 30px
  height 2px
  background #fff
.home_plate {
  margin-bottom: calc(50px + constant(safe-area-inset-bottom));
  margin-bottom: calc(50px + env(safe-area-inset-bottom));
}

.home_bottom {
  color: #969799;
  font-size: 14px;
  text-align: center;
}
</style>