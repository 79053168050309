<template>
  <div class="hot">
    <p class="hot_imageview">
      <img src="../assets/zan.png" alt="" @error="imgError" class="hot_image" />
    </p>
    <div :class="lists[0].id ? 'hot_list' : 'hot_list x_default'">
      <div
        v-for="item in lists"
        :key="item.id"
        class="hot_item"
        @click="itemClick(item)"
      >
        <p class="hot_iconview">
          <img :src="item.icon" alt="" @error="imgError" class="hot_icon" />
        </p>
        <span class="hot_name">{{ item.name }}</span>
        <span class="hot_tip">{{ item.tip }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImage from "@/assets/1.jpg";
import { isiOS } from "@/utils/index";
export default {
  name: "Swipe",
  props: ["lists", "route", "routeios"],
  data() {
    return {};
  },
  methods: {
    itemClick(item) {
      if(item.id != null) {
        store.commit("incrementIn");
        // store.commit("tabbarOut");
        if(isiOS) {
          this.$router.push(this.routeios + "?id=" + item.id);
        } else {
          this.$router.push(this.route + "?id=" + item.id);
        }
      }
    },
    imgError() {
      let img = event.srcElement;
      img.src = defaultImage;
      img.onerror = null; //防止闪图
    },
  },
};
</script>

<style scoped lang="stylus">
@import './common.styl';

.hot {
  margin: 4px auto 0;
  width: $viewWidth;
  height: 162px;
  background: $themeWhite;
  border-radius: 8px;
  border:1px solid transparent
}

.hot_imageview {
  width: 134px;
  margin-top: 10px;
  margin-left: 10px;
} 
.hot_image {
  width: 100%;
  height 100%
}

.hot_list {
  display: flex;
  overflow-x: scroll;
  margin-left: 8px;
  margin-right: 8px;
}

.hot_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 12px;
  width: 85px;
  min-width: 85px;
}

.hot_iconview {
  height: 68px;
  width: 68px;
  border-radius: 16px;
}

.hot_icon {
  height: 100%;
  width: 100%;
  border-radius: 16px;
}

.hot_name {
  font-size: 14px;
  color: #4d4946;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 5px;
  margin-top: 4px;
}

.hot_tip {
  font-size: 12px;
  color: #959292;
  margin-top: 2px;
}
</style>
