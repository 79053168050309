<template>
  <div class="signin">
    <img src="../assets/signin.png" alt="" class="signin_img">
    <div class="sign_center">
      <img src="../assets/close.png" class="sign_close" alt="" @click="onclose">
      <div class="sign_view">
        <div class="sign_set" v-for="(item,index) in items" :key="index">
          <div class="sign_top" v-if="duration <= index">未签到</div>
          <div class="sign_top" style="color:#000" v-if="duration > index">已签到</div>
          <div class="sign_content" v-if="duration <= index">
            <span class="sign_day">{{index + 1}}天</span>
            <img :src="noSign" alt="" class="sign_img">
          </div>
          <div class="sign_content" style="background:#e6e6e6" v-if="duration > index">
            <span class="sign_day">{{index + 1}}天</span>
            <img :src="sign" alt="" class="sign_img">
          </div>
        </div>
      </div>
      <div>
        <div class="sign_txt">累计签到<span class="sign_txt_day">{{duration}}</span>天</div>
        <div class="sign_text">
          <div class="sign_line"></div>
          <span class="sign_tip">今日签到可领 <span style="color:#ff0e00">+0.5</span> 糖豆</span>
          <div class="sign_line"></div>
        </div>
        <div class="sign_button" @click="onclick" :style="havesign == '已签到' ? 'background:#e6e6e6' : 'background:linear-gradient(90deg,#ff5555 50%,#ff0000 100%);' ">{{havesign}}</div>
      </div>
      <div class="sign_rule">
        <span style="text-align: center;margin-bottom: 4px;">活动规则</span>
        <span style="font-size:10px;color:#b8b8b8;margin-left:16px">每日签到可以得到0.5个糖豆</span>
        <span style="font-size:10px;color:#b8b8b8;margin-left:16px">连续签到10天, 可以获得双倍奖励</span>
      </div>
    </div>
  </div>
</template>
<script>
import signImg from '@/assets/signed.png'
import noSignImg from '@/assets/nosign.png'
export default {
  props: ['havesign', 'duration'],
  data() {
    return {
      items: 10,
      sign: signImg,
      noSign: noSignImg,
    }
  },
  methods: {
    signClose() {

    },
    onclick(item) {
      this.$emit('clickevent', item)
    },
    onclose(item) {
      this.$emit('clickclose', item)
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../components/common.styl';
.signin
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  width 100%
  height 100%
  z-index 100
  background rgba(0,0,0,0.5)
  display: flex;
  justify-content: center;
  align-items: center;
.signin_img
  width: 350px;
  height: 616px;
  margin-top: -60px;
.sign_center
  position absolute
  top 50%
  width: 230px;
  height 540px
  margin-top: -140px;
.sign_close
  position absolute
  top -92px
  right -42px
  height 30px
  width 30px
.sign_view
  display flex
  flex-wrap: wrap;
.sign_top
  margin-bottom 2px
  font-size 12px
  color #919191
.sign_content
  width 40px
  height 46px
  background: linear-gradient(180deg, $themeMin 35%, $themeMax 100%);
  border-radius 5px
  display flex
  flex-direction column
  justify-content center
  align-items center
.sign_day
  font-size 12px
  margin-bottom 2px
  color #ffffff
.sign_set
  text-align center
  margin: 0 3px 16px
.sign_img
  width 20px
  height 16px
.sign_txt
  font-size 16px
  font-weight 600
  text-align: center;
.sign_txt_day
  color #ff0e00
.sign_text
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top 3px
  margin-bottom 3px
.sign_line
  width 40px
  height 1px
  background #b8b8b8
.sign_tip
  font-size: 10px;
  color: #b8b8b8;
  padding: 0 8px;
.sign_button
  height 32px
  line-height 32px
  width 220px
  border-radius 20px
  background: linear-gradient(90deg, $themeMin 35%, $themeMax 100%);
  font-size 14px
  color #fff
  text-align center
  margin: 0 auto;
.sign_rule
  display flex
  flex-direction column
  font-size 12px
  margin-top 32px
</style>