import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
window.store = store
import VueTouch from 'vue-touch'
import { ajax2 } from "@/utils/index";
import { Decrypt } from "@/utils/index";
import { ksort } from "@/utils/ksort";

import {
  Tabbar,
  TabbarItem,
  Lazyload,
  TreeSelect,
  Tab,
  Tabs,
  ActionSheet,
  Field,
  List,
  Overlay,
  RadioGroup, 
  Radio,
  Cell, 
  CellGroup,
  CouponCell, 
  CouponList,
  Popup,
  Form,
  Picker,
  Checkbox, 
  CheckboxGroup,
  Loading
} from 'vant'
import 'vant/lib/index.css'
import md5 from 'blueimp-md5'

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer)

Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Lazyload)
Vue.use(TreeSelect)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(ActionSheet)
Vue.use(Field)
Vue.use(List)
Vue.use(Overlay)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(CouponCell)
Vue.use(CouponList)
Vue.use(Popup)
Vue.use(Form)
Vue.use(Picker)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Loading)

Vue.use(VueTouch, {
  name: 'v-touch'
})



// store.commit('incrementIn') 前进
// store.commit('incrementOut') 后退
import { Dialog } from "vant";

Vue.config.productionTip = false

var u = navigator.userAgent;
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

window.judgeBigScreen = () => {
  if(isAndroid) {
    let result = false;
    const rate = window.screen.height / window.screen.width;
    let limit = window.screen.height == window.screen.availHeight ? 1.8 : 1.65; // 临界判断值
    if (rate > limit) {
      result = true;
    }
    return result;
  } else {
    return false
  }
}

window.judgeBigScreenIOS = () => {
  if(isiOS) {
    let result = false;
    const rate = window.screen.height / window.screen.width;
    let limit = window.screen.height == window.screen.availHeight ? 1.8 : 1.65; // 临界判断值
    if (rate > limit) {
      result = true;
    }
    return result;
  } else {
    return false
  }
}

if (!window.localStorage.getItem('CYBoxIDFA')) {
  window.localStorage.setItem("CYBoxIDFA", md5(Date.now() + Math.random()))
  store.commit('IDFAUpdate', md5(Date.now() + Math.random()))
}
var Tip = 0
router.beforeEach(({meta, path}, from, next) => {
  if(Tip == 0) {
    if(store.state.CYBoxUser) {
      var params = {
        appid: store.state.appid,
        username: store.state.CYBoxUser.loginName,
        password: store.state.CYBoxUser.password.length < 13 ? store.state.CYBoxUser.password : Decrypt(store.state.CYBoxUser.password),
        time: Math.floor(Date.now() / 1000),
        eventType: 1,
        currentAppBuild: store.state.bundle,
        currentDeviceIdfa: "",
        deviceId: store.state.CYBoxIDFA,
      };
      params.sign = ksort(params, store.state.appkey);
      ajax2({
        method: "POST",
        url: "/Login/login",
        data: params,
        success: res => {
          if (res.state === "1") {
            store.commit("listUpdate", res.message)
            store.commit("userUpdate", res.message)
            store.commit("loginIn")
          } else {
            Dialog.alert({
              message: "登录状态已过期, 请重新登录",
              theme: "round-button",
            }).then(() => {
              store.commit("loginOut");
            });
          }
          next()
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
  Tip++
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//