<template>
  <swiper
    ref="mySwiper"
    :options="swiperOptions"
    @clickSlide="handleSwiperSlideClick"
    :style="'height:'+ availWidth + 'px;margin-top: calc(' + marginTop + 'px + constant(safe-area-inset-bottom));margin-top: calc(' + marginTop + 'px + env(safe-area-inset-bottom));'"
    class="swipe_width"
  >
    <swiper-slide :key="banner.id" v-for="banner in lists">
      <img
        :src="banner.image + '?t=' + Date.now()"
        :data-id="banner.id"
        class="swiper_image"
        alt=""
      />
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { isiOS } from "@/utils/index";
import "swiper/css/swiper.css";

export default {
  name: "App",
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  props: ["lists", "route", "routeios"],
  data() {
    return {
      availWidth: 0,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      },
      marginTop: 40
    };
  },
  created() {
    this.availWidth = Math.floor(document.body.offsetWidth / 2.04);
    this.availWidth = 250;
    if (judgeBigScreen()) {
      this.marginTop = 70
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    handleSwiperSlideClick(index, reallyIndex) {
 
      if(reallyIndex != null) {
        store.commit("incrementIn");
        // store.commit("tabbarOut");
        if(isiOS) {
          this.$router.push(this.routeios + "?id=" + this.lists[reallyIndex].id);
        } else {
          this.$router.push(this.route + "?id=" + this.lists[reallyIndex].id);
        }
      }
    },
  },
  mounted() {
    this.swiper.slideTo(1, 1000, false);
  
  },
};
</script>

<style scoped lang="stylus">
.swipe_width
  width 96%
  border-radius: 5px;
.swiper_image {
  width: 100%;
  height 100%
  border-radius: 5px;
}
</style>
