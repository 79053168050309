<template>
  <div class="played">
    <div class="played_left">
      我玩过的
      <i class="played_triangle"></i>
    </div>
    <div class="played_view">
      <div v-for="item in lists" :key="item.id" class="played_item" @click="onclick(item)">
        <img :src="item.icon" alt="" class="played_icon">
        <span class="played_name">{{item.name}}</span>
      </div>
    </div>
    <!-- <div class="played_right">更多游戏</div> -->
  </div>
</template>

<script>
import { isiOS } from "@/utils/index";
export default {
  name: "Played",
  props: ['lists', 'route', 'routeios'],
  data() {
    return {
      
    };
  },
  methods: {
    onclick(item) {
      if(item.id != null) {
        store.commit('incrementIn')
        // store.commit('tabbarOut')
        if(isiOS) {
          this.$router.push(this.routeios + '?id=' + item.id)
        } else {
          this.$router.push(this.route + '?id=' + item.id + '&source=played')
        }
      }
    }
  }
};
</script>

<style scoped lang="stylus">
@import './common.styl';
  
.played
  width 100%
  height 80px
  display flex
  background $themeWhite
  border-bottom 2px solid #f3f3f3
  position fixed
  bottom calc(50px + constant(safe-area-inset-bottom))
  bottom calc(50px + env(safe-area-inset-bottom))
  left 0 
  right 0
  z-index 100
.played_right, .played_left
  width 20px
  min-width 20px
  height 100%
  font-size 14px
  line-height 14px
  display flex
  align-items center
  text-align center
  background #fe2d2d
  color #fff
.played_left
  border-radius 0 2px 2px 0
  position relative
.played_triangle
  width: 0;
  height: 0;
  border-left: 8px solid #fe2d2d;
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  position absolute
  right -6px
.played_right
  border-radius 2px 0 0 2px
.played_icon
  height 54px
  width 54px
  border-radius 12px
  margin-bottom 2px
.played_name
  font-size 14px
  color #8e8e8e
  width 80px
  overflow: hidden;
  white-space: nowrap;
  text-align center
.played_view
  // width calc(100% - 40px)
  display flex
  overflow-x: scroll;
  align-items center
.played_item
  display flex
  flex-direction column
  justify-content center
  align-items center
  // margin 0 9px
</style>
