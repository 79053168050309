import md5 from 'blueimp-md5'

export function ksort(object,appkey) {
  let arr = [], str = ''
  for (let attr in object) {
    arr.push(attr)
  }
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[j] < arr[i]) {
        let a = arr[j]
        arr[j] = arr[i]
        arr[i] = a
      }
    }
  }
  for (let i = 0; i < arr.length; i++) {
    str += arr[i] + '=' + object[arr[i]] + '&'
  }
  str += 'key=' + appkey
  return md5(str).toUpperCase()
}

export function ksortF(object,appkey) {
  let arr = [], str = ''
  for (let attr in object) {
    arr.push(attr)
  }
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[j] < arr[i]) {
        let a = arr[j]
        arr[j] = arr[i]
        arr[i] = a
      }
    }
  }
  for (let i = 0; i < arr.length; i++) {
    str += object[arr[i]]
  }
  str = str + appkey
  return md5(str)
}