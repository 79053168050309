
<template>
  <div class="notice">
    <img src="../assets/notice.png" alt="" class="notice_icon" />
    <div :class=" message[0].id ? 'notice_view' : 'notice_view x_default'">
      <transition name="slide">
        <span class="notice_message" :key="text.id">{{ text.val }}</span>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notice",
  props: ["message"],
  data() {
    return {
      number: 0,
    };
  },
  mounted() {
    this.startMove();
  },
  computed: {
    text() {
      return {
        id: this.number,
        val: this.message[this.number].text,
      };
    },
  },
  methods: {
    startMove() {
      setTimeout(() => {
        if (this.number === this.message.length - 1) {
          this.number = 0;
        } else {
          this.number += 1;
        }
        this.startMove();
      }, 3000);
    },
  },
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s linear;
}
.slide-enter {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
.slide-leave-to {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}
</style>

<style scoped lang="stylus">
@import './common.styl';

.notice { 
  height: 32px;
  background: $themeWhite;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  width 96%
  margin 4px auto
  border-radius 5px
}

.notice_icon {
  margin-left: 16px;
  height: 24px;
  width: 28px;
}

.notice_view {
  width: 88%;
  position: relative;
  height: 35px;
  overflow: hidden;
}

.notice_message {
  width: 98%;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  position: absolute;
  bottom: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  list-style: none;
  margin-bottom: -9.5px;
}
</style>