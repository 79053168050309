<template>
  <div id="app">
    <transition :name="count">
      <!-- <keep-alive> -->
        <router-view></router-view>
      <!-- </keep-alive> -->
    </transition>
    <transition :name="fade">
      <van-tabbar
        route v-if="tabbar" @change="onChange" v-model="active"
        active-color="#ff5050" inactive-color="#bfbfbf" fixed safe-area-inset-bottom
      >
        <van-tabbar-item replace to="/">
          <span>首页</span>
          <template #icon="props">
            <img :src="props.active ? home.active : home.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/find">
          <span>找游戏</span>
          <template #icon="props">
            <img :src="props.active ? find.active : find.inactive" />
          </template>
        </van-tabbar-item>

        <van-tabbar-item replace to="/card">
          <span>省钱</span>
          <template #icon="props">
            <img :src="props.active ? card.active : card.inactive" />
          </template>
        </van-tabbar-item>

        <van-tabbar-item replace to="/customer">
          <span>客服</span>
          <template #icon="props">
            <img :src="props.active ? customer.active : customer.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/my">
          <span>我的</span>
          <template #icon="props">
            <img :src="props.active ? my.active : my.inactive" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </transition>
  </div>
</template>
<script>
import homeIcon from "@/assets/home.png";
import inhomeIcon from "@/assets/inhome.png";
import customerIcon from "@/assets/customer.png";
import incustomerIcon from "@/assets/incustomer.png";
import findIcon from "@/assets/find.png";
import infindIcon from "@/assets/infind.png";
import myIcon from "@/assets/my.png";
import inmyIcon from "@/assets/inmy.png";

import provactiveIcon from '@/assets/province.png'
import provIcon from '@/assets/provactive.png'

import { mapGetters, mapState, mapActions } from "vuex";
import { UPDATE_USER } from "@/store";
import { ksort } from "@/utils/ksort";
import { Dialog } from "vant";
import { loadPageVar, Encrypt, Decrypt, ajax, ajax2 } from "@/utils/index";
import { askAnaly } from "@/api";
import router from './router'

export default {
  data() {
    return {
      rotate: 0,
      onum: 0,
      active: "1",
      home: {
        active: homeIcon,
        inactive: inhomeIcon,
      },
      customer: {
        active: customerIcon,
        inactive: incustomerIcon,
      },
      card: {
        active: provIcon,
        inactive: provactiveIcon,
      },
      find: {
        active: findIcon,
        inactive: infindIcon,
      },
      my: {
        active: myIcon,
        inactive: inmyIcon,
      },
    };
  },
  mounted() {
    this.getLogin()
  },
  methods: {
    ...mapActions([UPDATE_USER]),
    getLogin() {
      if (!this.CYBoxUser) {
        if (loadPageVar("s")) {
          var getS = loadPageVar("s").slice(2)
          if(getS.length == 28) {
            var openid = getS
            if(openid) {
              var params = {
                appid: this.appid,
                eventType: 2,
                currentAppBuild: this.bundle,
                currentDeviceIdfa: "",
                deviceId: openid
              }
              params.sign = ksort(params, this.appkey)
              ajax2({
                method: "POST",
                url: '/Login/getName',
                data: params,
                success: res => {
                  if (res.state === "1") {
                    var msg = res.message
                    this.UPDATE_USER(msg)
                    store.commit("listUpdate", msg)
                    store.commit("loginIn")
                    var param = {
                      token: msg.loginToken,
                      time: Math.floor(Date.now() / 1000),
                    }
                    param.sign = ksort(param, "qsdhajasfas123sq")
                    ajax({
                      method: "POST",
                      url: "/user/history",
                      data: param,
                      success: res => {
                        if (res.code === 1) {
                          var data = [];
                          res.data.map((e) => {
                            data.push({
                              id: e.gb_game_list.id,
                              icon: e.gb_game_list.icon,
                              name: e.gb_game_list.name,
                            });
                          });
                          store.commit("addHistory", data[0] ? data : null);
                        }
                      }
                    })
                    Dialog.alert({
                      title: "已自动登录账号",
                      message: "请检查与小程序账号是否一致",
                      theme: "round-button",
                    }).then(() => {});
                  }
                }
              })
            }  
          }
          
        }
      }
      if(location.href.indexOf('wx_work') > -1) {
        localStorage.setItem('isKefu', 1)
      }
    },
    onChange(index) {
      if (index > this.onum) {
        store.commit("incrementIn");
      }
      if (index < this.onum) {
        store.commit("incrementOut");
      }
      this.onum = index;
    }
  },
  computed: {
    ...mapGetters([
      "count",
      "tabbar",
      "fade",
      "CYBoxUser",
      "appid",
      "bundle",
      "CYBoxIDFA",
      "appkey",
    ]),
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-style: normal;
  text-decoration: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  -webkit-touch-callout: none;
}
html,
body {
  height: 100vh;
  position: relative;
  background: #f4f4f4;
  width: 100%;
}

::-webkit-scrollbar {
  display: none;
}
.words_view b{
}
.words_view i{
  margin-left: 1em;
  display: block;
}
.words_view li{
  list-style: outside;
}

.container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.vux-pop-out-enter-active,
.vux-pop-out-leave-active,
.vux-pop-in-enter-active,
.vux-pop-in-leave-active {
  will-change: transform;
  transition: all 500ms;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  perspective: 1000;
}
.vux-pop-out-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.vux-pop-out-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.vux-pop-in-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.vux-pop-in-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fade-in-enter-active,
.fade-in-leave-active,
.fade-out-enter-active,
.fade-out-leave-active {
  will-change: transform;
  position: fixed;
  transition: all 500ms;
  bottom: 0;
  left: 0;
  right: 0;
}

.fade-in-enter {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
.fade-in-leave-active {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
.fade-out-enter {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
.fade-out-leave-active {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
.van-overlay {
  /* z-index: 100 !important; */
}
.van-cell__left-icon {
  font-size: 28px !important;
}
.van-list__finished-text {
  margin-bottom: 10px;
}

</style>
<style lang="stylus">
@import './components/common.styl';

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(30%, 0, 0);
  }
}

.x_default {
  span {
    background: $defaultColor !important;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0) !important;
    border: 0 solid rgba(0, 0, 0, 0) !important;
    position: relative;
    display: inline-block;
    overflow: hidden;
  }

  span::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation 0.8s linear infinite;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
  }

  img {
    opacity: 0;
  }

  p {
    background: $defaultColor;
    border-radius: 5px !important;
    position: relative;
  }

  p::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation 0.8s linear infinite;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
  }
}

.pageGameAndroid {
  margin-top: 66px !important;
}

.pageTopAndroid {
  padding-top: 26px !important;
  height: 66px !important;
}

.pageTopIOS {
  padding-top: 44px !important;
  // height: 66px !important;
}
.pageGameIOS {
  margin-top: 88px !important;
}

.findTabAndroid {
  height: 60px !important;
}

.findSelectAndroid {
  margin-top: 60px !important;
}

.findItemAndroid {
  margin-top: 28px !important;
}

.customerAndroid {
  height: 126px !important;
}

.customerTimeAndroid {
  padding-top: 16px !important;
}
.homeBgAndroid {
  .home_l {
    padding-top: 24px;
  }
  .home_r {
    top 32px
  }
}
.myTopAndroid {
  height: 128px !important;
}
.cardTopAndroid {
  height: 90px !important;
  span {
    top: 30px !important
  }
}

.playedTopAndroid {
  height: 144px !important;
  div {
    top: 34px !important;
  }
}
.playedListAndroid {
  top: 66px !important;
}
.invitaionTopAndroid {
  .invitaion_back {
    top: 32px !important
  }
  .invitaion_title {
    top: 30px !important
  }
}  

.myLeftAndroid, .myOutAndroid {
  margin-top: 26px !important;
}

.gameCoreHAndroid {
  padding-bottom: 0 !important;
  padding-left: 30px !important;
}

.gameCoreSAndroid {
  padding-top: 30px !important;
}

.gameCloseAndroid {
  margin-top: 30px !important;
}

.swiper-pagination {
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #898989;
  }

  .swiper-pagination-bullet {
    background-color: #fff;
  }
}

.swiper-container {
  background: #f2f3f5;
}

/* 将有客对话窗口放到窗口左边 */
.youke-chat-window-frame {
  height: 80% !important;
  -webkit-transition: 0.5s ease-in-out !important;
  transition: 0.5s ease-in-out !important;
}

/* 将有客对话按钮放到窗口左边 */
.youke-launcher-frame {
  height: 80% !important;
  -webkit-transition: 0.5s ease-in-out !important;
  transition: 0.5s ease-in-out !important;
}

.van-sidebar {
  flex: 0.5 !important;
}

.van-tab {
  font-size: 16px !important;
}

.van-tabs__wrap {
  font-weight: 600 !important;
}

.van-tab--active {
  color: $themeMax !important;
  font-weight: 600 !important;
}

.van-tabs__line {
  width: 15px !important;
  background-color: $themeMax !important;
}

.van-sidebar-item {
  font-size: 16px !important;
}

.van-sidebar-item--select {
  color: $themeMax !important;
  font-weight: 600 !important;
}

.van-sidebar-item--select::before {
  background-color: $themeMax !important;
  height: 22px !important;
}

.van-tabs__content {
  width: 100vw !important;
}

.van-action-sheet__header {
  background: #f2f2f6 !important;
  color: $themeMax !important;
  font-weight: 600 !important;
}

.van-action-sheet__close {
  color: $themeMax !important;
}

.van-popup {
  background: #f2f2f6 !important;
}

.van-tabbar-item {
  font-size: 10px !important;
}
.van-tabbar-item__icon {
  img {
    height 26px !important;
  }
}

.my_content {
  height: 80vh;
  background: #f2f2f6;
}

.my_txt {
  font-size: 14px;
  padding: 1px 16px;
  background: #f2f2f6;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.my_field {
  margin-bottom: 6px;
}

.my_save_btn {
  margin: 18px auto;
  width: 100px;
  height: 34px;
  line-height: 34px;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  background: linear-gradient(90deg, $themeMin 35%, $themeMax 100%);
}

.my_login_btn {
  font-size: 14px;
  margin-bottom: 0;
}

.my_other {
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  span {
    padding: 14px 10px;
  }
}

.my_code {
  position: relative;
}

.my_getcode {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -15px;
  width: 76px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  background: linear-gradient(90deg, $themeMin 35%, $themeMax 100%);
}
.my_saveps {
  font-size:14px;
  display:flex;
  justify-content: space-between;
  padding-right:6px;
  padding-left:6px;
  padding-top:6px;
}
.card_cur
  background #fff
  height 40px
  border-radius: 3px;
  width: 96%;
  margin: 0 auto 6px;
  display flex
  justify-content: space-between;
  align-items: center;
  padding 0 18px
.card_cur_pay
  height 20px
  width 54px
  border-radius 10px
  background linear-gradient(0deg, #ff1523 50%, #ff6149 100%)
  color #fff
  text-align center
</style>