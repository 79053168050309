<template>
  <div class="plate">
    <div class="plate_title">{{ title }}</div>
    <div class="plate_list">
      <Strip v-for="(item, index) in lists" :key="item.id" :item="item" :index="index" route="/page" routeios="/pageios" :isHome="true"></Strip>
    </div>
  </div>
</template>
<script>
import Strip from '@/components/Strip.vue'
export default {
  // 
  props: ["title", "lists", 'route', 'more', 'type', 'routeios'],
  components: {
    Strip
  },
  data() {
    return {};
  },
  methods: {
    
  }
};
</script>
<style scoped lang="stylus">
@import './common.styl';

.plate {
  width: $viewWidth;
  background: $themeWhite
  margin: 10px auto;
  border-radius: 8px;
  padding-bottom .1px
  position relative
}
.plate_more {
  font-size 12px
  color $themeColor
  position absolute
  right 14px
  top 14px
}

.plate_title {
  font-weight: 600;
  padding-left: 10px;
  padding-top: 14px;
}

.plate_item {
  display: flex;
  flex-direction column
  padding-left: 10px;
  margin: 18px 0;
  justify-content: space-between;
}

.plate_view {
  display: flex;
  flex-direction row
  align-items: center;
  justify-content: space-between;
}

.plate_icon {
  height: 68px;
  width: 68px;
  border-radius: 15px;
  margin-right: 10px;
}

.plate_pos {
  display: flex;
  align-items: center;
}

.plate_name {
  font-size: 16px;
  font-weight: 600;
}

.plate_type {
  color: #959595;
  font-size: 10px;
  margin-right: 4px;
}

.plate_tip {
  color: #f21919;
  font-size: 10px;
  border: 1px solid #f21919;
  border-radius: 5px;
  padding: 1px 3px;
}

.plate_des {
  font-size: 12px;
  color: #959595;
}

.plate_start {
  height: 22px;
  line-height: 22px;
  width: 50px;
  text-align: center;
  color: #fff;
  border-radius: 25px;
  background: linear-gradient(90deg, #ff5659 30%, #ff0103 100%);
  font-size: 14px;
  margin-right: 14px;
}

.plate_banner
  width 98%
  margin-top 8px
</style>